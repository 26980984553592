.menu {
  position: absolute;
  right: 0px;
  top: 45px;
	z-index: 2;
	border-radius: 0 0 0 9px;
	background-color: #333;
	color: #fff;
	overflow: hidden;
	transition: max-height 0.2s ease;

	ul {
		margin: 6px;
		padding: 6px;;
		list-style: none;
		user-select: none;
		li {
			text-align: right;
			padding: 6px;
			cursor: pointer;
			a {
				text-decoration: none;
				color:#fff;
			}
		}
	}

}
.menu.open {
	max-height: 200px;
}
.menu.closed {
	max-height: 0px;
}
