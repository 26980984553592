@import '../../styles/colors.scss';

.infopage {
    animation: pagefadein .3s ease forwards;
    background-color: #fff;
    margin-top: 90px;
    padding: 30px 30px 30px 30px;
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .infopage {
        margin-top: 60px;
        padding: 30px 30px 30px 60px;
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .infopage {
        padding: 30px 30px 30px 60px;
    }
}
