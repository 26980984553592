// needs fine-tuning
// see https://css-tricks.com/considerations-styling-modal/
@import '../../styles/colors.scss';
.modal {
    position: absolute;
    width: 100%;
    z-index: 2;
    .modal-overlay {
        position: fixed;
        z-index: 3;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
        background: rgba(255, 255, 255, 0.8);
    }
    .modal-popup {
        position: fixed;
        z-index: 4;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -30%);
        background-color: #fff;
        border-radius: 9px;
        padding: 0;
        width: 510px;
        max-width: 90%;
        height: 630px;
        max-height: 90%;
        box-sizing: border-box;
        overflow: hidden;
        text-align: left;
        box-shadow: 0 6px 12px 0 rgba(0, 0, 0, .3);
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        box-shadow: 0 0px 24px 0 rgba(0, 0, 0, .5);
        .modal-title {
            min-height: 21px;
            margin: 0;
            padding: 12px 45px 12px 30px;
            background-color: $kleur-bijnazwart;
            color: #fff;
            font-size: 18px;
            z-index: 2;
            position: relative;
        }
        .modal-content {
            padding: 12px 30px;
            line-height: 24px;
            color: #333;
            position: absolute !important;
            top: 60px;
            left: 0;
            right: 0;
            bottom: 0;
            //width: 100%;
            //height: 100%;
            overflow: auto;
            h6 {
                font-family: 'SourceSans-Semibold', sans-serif;
                padding: 0;
                margin: 12px 0 6px 0;
                font-size: 16px;
                color: $kleur-achtergrond;
            }
            ul {
                margin: 0 0 12px 0;
                padding: 0;
                list-style: none;
                li {
                    padding: 0px 0 2px 24px;
                    position: relative;
                    p {
                        padding: 0px 0 2px 0px;
                        line-height: 21px;
                        margin: 0px 0;
                    }
                }
                li:before {
                    content: ' ';
                    position: absolute;
                    top: 6px;
                    left: 2px;
                    width: 15px;
                    height: 15px;
                    background-image: url(../../styles/img/station.svg);
                }
            }
            a {
                color: #333;
            }
            dl {
                dt {
                    font-family: 'SourceSans-Semibold', sans-serif;
                }
                dd {
                    padding: 0;
                    margin: 0 0 12px 0;
                }
            }
        }
        .modal-close {
            position: absolute;
            right: 0;
            top: 0;
            border: none;
            background: none;
            -webkit-appearance: none;
            .kruis {
                width: 39px;
                height: 39px;
                stroke: #fff;
                cursor: pointer;
                line {
                    transition: all 0.3s ease;
                    transform-origin: 50% 50%;
                }
            }
            .kruis:hover {
                .lijn1 {
                    transform: rotate(45deg)
                }
                .lijn2 {
                    transform: rotate(-45deg)
                }
            }
        }
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeout {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        height: 0px;
    }
}

.modal.closed {
    animation: fadeout .3s ease forwards;
    pointer-events: none;
    .modal-content,
    .modal-popup {
        position: absolute;
    }
}

.modal.open {
    animation: fadein .3s ease forwards;
    pointer-events: all;
    .modal-content,
    .modal-popup {
        position: fixed;
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .modal {
        .modal-popup {
            .modal-content {
                top: 45px;
            }
        }
    }
}
