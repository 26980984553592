@import '../../styles/colors.scss';
.social {
    text-align: left;
    a {
        height: 45px;
        display: inline-block;
    }
    .icon {
        width: 45px;
        fill: $kleur-fabrieksblauw;
    }
    .icon:hover {
        transition: all 0.3s ease;
        transform: scale(1.2);
    }
}
