@import '../../styles/colors.scss';
.app {
	position: relative;
  max-width: 1024px;
  width: auto;
  margin: 0 auto;
  background-color: #fff;
  .page {
    min-height: calc(100vh - 129px);
    box-sizing: border-box;
  }
}
.app.modalOpen {
	.header,
	.page,
	.footer {
		filter: blur(2px);
	}
}
