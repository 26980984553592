@import '../../styles/colors.scss';
.footer {
	position: relative;
	box-sizing: border-box;
	width: 100%;
	//height: 60px;
	border-top: 2px solid;
	padding: 3px 12px 6px 12px;
	background-color: #fff;
	text-align: center;
	overflow: hidden;
	.logo.fabriek {
		height: 30px;
		max-width: 210px;
		.tekst {
			display: none;
		}
	}

	.logoUmc {
		height: 38px;
		max-width: 427px;
		transform: translateY(9px);
		margin-right: 50px;
	}

	.logo.vumc {
		height: 38px;
		max-width: 127px;
		margin-right: 21px;
	}
	.logo.amc {
		height: 40px;
		max-width: 64px;
		transform: translateY(16px);
		margin-right: 15px;
	}
	.social {
		display: block;
		width: 180px;
		margin: 0 auto;
		margin-top: 9px;
	}
}



@media (min-width: 576px) {
    .footer {
			text-align: left;
        .social  {
            float: right;
        }
    }
}
