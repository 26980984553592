@import '../../styles/colors.scss';
@keyframes open {
    0% {
        max-height: 0px;
    }
    99% {
        max-height: 1000px;
    }
    100% {
        max-height: inherit;
    }
}

@keyframes close {
    0% {
        max-height: 1000px;
    }
    100% {
        max-height: 0px;
    }
}

.harmonica {
    .harmonica-text {
        position: relative;
        .arrow {
            position: absolute;
            top: 0;
            right: 0;
            width: 45px;
            height: 45px;
            stroke: #fff;
            stroke-width: 4.5;
            transition: all 0.3s ease;
            transition-origin: 50% 50%;
        }
    }
    .harmonica-text.initial-open,
    .harmonica-text.open {
        .arrow {
            transform: rotate(90deg);
        }
    }
    .pijl:hover {
        transform: scale(1.1);
    }
    .harmonica-text.expandable {
        cursor: pointer;
    }
    .harmonica-children {
        position: relative;
        overflow: hidden;
        padding: 0;
    }
    .harmonica-children.open {
        animation: open 1s ease forwards;
    }
    .harmonica-children.closed {
        animation: close 0.3s forwards;
    }
    .harmonica-children.initial-closed {
        max-height: 0;
    }
}

.harmonica.level0 {
    background-color: rgba($kleur-bijnazwart, .15);
    border-radius: 30px;
    margin-bottom: 21px;
    .harmonica-text {
        font-family: 'SourceSans-Semibold', sans-serif;
        background-color: $kleur-bijnazwart;
        border-radius: 30px;
        padding: 12px 45px 12px 21px;
        margin: 0;
        font-size: 18px;
        color: #fff;
    }
}

.harmonica.level1 {
    margin: 12px;
    .harmonica-text {
        font-family: 'SourceSans-Semibold', sans-serif;
        background-color: rgba($kleur-bijnazwart, .75);
        border-radius: 30px;
        padding: 12px 45px 12px 21px;
        margin: 0;
        font-size: 18px;
        color: #fff;
    }
}

.harmonica.level2 {
    background-color: none;
    //margin: 9px 15px 9px 27px;
    margin: 9px 0px 9px 0px;
    .harmonica-text {
        background-color: $kleur-bijnazwart;
        display: inline-block;
        //width: 240px;
    }
}

.harmonica.level3 {
    padding: 9px 0 0 0;
    margin: 0px;
    .harmonica-text {
        font-family: 'SourceSans-Semibold', sans-serif;
        display: inline-block;
        margin-bottom: -9px;
        vertical-align: super;
        box-sizing: border-box;
        background-color: rgba($kleur-bijnazwart, .25);
        border: 2px solid $kleur-bijnazwart;
        color: $kleur-bijnazwart;
        width: 100%;
        border-radius: 12px 12px 0 0;
        font-size: 16px;
        padding: 9px 12px 12px 12px;
        width: auto;
    }
    .harmonica-children {
        box-sizing: border-box;
        width: 100%;
        background-color: #fff;
        border-radius: 12px;
        border: 2px solid $kleur-bijnazwart;
        padding: 6px 12px 6px 6px;
    }
}

.harmonica.level4 {
    margin-bottom: 12px;
    padding: 0;
    margin: 0;
    .harmonica-text {
        font-family: 'SourceSans-Regular', sans-serif;
        display: block;
        vertical-align: baseline;
        margin: 0;
        padding: 0px 0 0px 24px;
        line-height: 24px;
        background-color: #fff;
        border: none;
        color: #333;
    }
    .harmonica-text:before {
        content: ' ';
        position: absolute;
        top: 6px;
        left: 2px;
        width: 15px;
        height: 15px;
        background-image: url(../../styles/img/station.svg);
    }
    .harmonica-children {
        display: none;
    }
}

.operaties {
    .harmonica.level0 {
        background-color: rgba($kleur-achtergrond, .15);
        .harmonica-text {
            background-color: $kleur-achtergrond;
        }
    }
    .harmonica.level1 {
        .harmonica-text {
            background-color: rgba($kleur-achtergrond, .75);
        }
        .harmonica.level2 {
            .harmonica-text {
                background-color: $kleur-bijnazwart;
            }
        }
        .harmonica.level3 {
            .harmonica-text {
                background-color: rgba($kleur-achtergrond, .25);
                border: 2px solid $kleur-achtergrond;
            }
            .harmonica-children {
                border: 2px solid $kleur-achtergrond;
            }
        }
        .harmonica.level4 {
            .harmonica-text {
                background-color: #fff;
                border: 0;
            }
        }
    }
}

.eicellen {
    .harmonica.level0 {
        background-color: rgba($kleur-oranje, .15);
        .harmonica-text {
            background-color: $kleur-oranje;
        }
    }
    .harmonica.level1 {
        .harmonica-text {
            background-color: rgba($kleur-oranje, .75);
        }
        .harmonica.level2 {
            .harmonica-text {
                background-color: $kleur-bijnazwart;
            }
        }
        .harmonica.level3 {
            .harmonica-text {
                background-color: rgba($kleur-oranje, .25);
                border: 2px solid $kleur-oranje;
            }
            .harmonica-children {
                border: 2px solid $kleur-oranje;
            }
        }
        .harmonica.level4 {
            .harmonica-text {
                background-color: #fff;
                border: 0;
            }
        }
    }
}

.embryos {
    .harmonica.level0 {
        background-color: rgba($kleur-blauw, .15);
        .harmonica-text {
            background-color: $kleur-blauw;
        }
    }
    .harmonica.level1 {
        .harmonica-text {
            background-color: rgba($kleur-blauw, .75);
        }
        .harmonica.level2 {
            .harmonica-text {
                background-color: $kleur-bijnazwart;
            }
        }
        .harmonica.level3 {
            .harmonica-text {
                background-color: rgba($kleur-blauw, .25);
                border: 2px solid $kleur-blauw;
            }
            .harmonica-children {
                border: 2px solid $kleur-blauw;
            }
        }
        .harmonica.level4 {
            .harmonica-text {
                background-color: #fff;
                border: 0;
            }
        }
    }
}

.weefsel {
    .harmonica.level0 {
        background-color: rgba($kleur-groen, .15);
        .harmonica-text {
            background-color: $kleur-groen;
        }
    }
    .harmonica.level1 {
        .harmonica-text {
            background-color: rgba($kleur-groen, .75);
        }
        .harmonica.level2 {
            .harmonica-text {
                background-color: $kleur-bijnazwart;
            }
        }
        .harmonica.level3 {
            .harmonica-text {
                background-color: rgba($kleur-groen, .25);
                border: 2px solid $kleur-groen;
            }
            .harmonica-children {
                border: 2px solid $kleur-groen;
            }
        }
        .harmonica.level4 {
            .harmonica-text {
                background-color: #fff;
                border: 0;
            }
        }
    }
}

.eierstok {
    .harmonica.level0 {
        background-color: rgba($kleur-paars, .15);
        .harmonica-text {
            background-color: $kleur-paars;
        }
    }
    .harmonica.level1 {
        .harmonica-text {
            background-color: rgba($kleur-paars, .75);
        }
        .harmonica.level2 {
            .harmonica-text {
                background-color: $kleur-bijnazwart;
            }
        }
        .harmonica.level3 {
            .harmonica-text {
                background-color: rgba($kleur-paars, .25);
                border: 2px solid $kleur-paars;
            }
            .harmonica-children {
                border: 2px solid $kleur-paars;
            }
        }
        .harmonica.level4 {
            .harmonica-text {
                background-color: #fff;
                border: 0;
            }
        }
    }
}



// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .harmonica.level0 {
        .harmonica-text {
            font-size: 18px;
        }
    }
    .harmonica.level1 {
        .harmonica-text {
            font-size: 18px;
            width: 360px;
        }
    }
    .harmonica.level2 {
        margin: 9px 15px 9px 27px;
        .harmonica-text {
            font-size: 18px;
            width: 240px;
        }
    }
    .harmonica.level3 {
        .harmonica-text {
            font-size: 16px;
        width: auto;
        }
    }
    .operaties {
        .harmonica.level1 {
            .harmonica-text {
                width: 240px;
            }
        }
        .harmonica.level3 {
            .harmonica-text {
                width: auto;
            }
        }
    }
}


