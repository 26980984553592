@import '../../styles/colors.scss';

@keyframes open {
    0% {
        max-height: 0px;
    }
    99% {
        max-height: 1000px;
    }
    100% {
        max-height: inherit;
    }
}

@keyframes close {
    0% {
        max-height: 1000px;
    }
    100% {
        max-height: 0px;
    }
}

.panel {
    background-color: rgba($kleur-bijnazwart, .15);
    border-radius: 30px;
    margin-bottom: 21px;
    .panel-title {
        font-family: 'SourceSans-Semibold', sans-serif;
        position: relative;
        background-color: $kleur-bijnazwart;
        border-radius: 30px;
        padding: 12px 33px 12px 21px;
        margin: 0;
        font-size: 18px;
        color: #fff;
        cursor: pointer;
        .arrow {
            position: absolute;
            top: 0;
            right: 0;
            width: 45px;
            height: 45px;
            stroke: #fff;
            stroke-width: 4.5
            ;
            transition: all 0.3s ease;
        }
        .pijl:hover {
        	transform: scale(1.1);
        }
    }
    .panel-title.open,
    .panel-title.initial-open {
        .arrow {
            transform: rotate(90deg);
        }
    }
    .panel-content {
        position: relative;
        overflow: hidden;
        padding: 0;
        .panel-box {
            margin: 18px 18px 18px 21px;
            padding: 18px;
            background-color: #fff;
            border: 2px solid $kleur-bijnazwart;
            border-radius: 18px;
        }
        .panel-box::after {
	        content: '';
	        clear: both;
	        display: table;
	      }
        h6 {
            font-family: 'SourceSans-Semibold', sans-serif;
            font-size: 16px;
            margin: 12px 0 6px 0;
            padding: 0;
        }
        p {
            margin: 0px 0 0 0;
        }
        ul {
            margin: 0;
            padding: 0;
            list-style: none;
            li {
                padding: 0px 0px 0px 24px;
                line-height: 24px;
                position: relative;
            }
            li:before {
                content: ' ';
                position: absolute;
                top: 6px;
                left: 2px;
                width: 15px;
                height: 15px;
                background-image: url(../../styles/img/station.svg);
            }
        }
        .diagram {
        	width: 90%;
        	max-width: 280px;
        	//float: right;
        }
    }
    .panel-content.open {
        animation: open 1s ease forwards;
    }
    .panel-content.closed {
        animation: close 0.3s forwards;
    }
    .panel-content.initial-closed {
        max-height: 0;
    }
}


.operaties {
    .panel {
        background-color: rgba($kleur-achtergrond, .15);
        .panel-title {
            position: relative;
            background-color: $kleur-achtergrond;
        }
        .panel-content {
            .panel-box {
                border: 2px solid $kleur-achtergrond;
            }
        }
    }
}

.eicellen {
    .panel {
        background-color: rgba($kleur-oranje, .15);
        .panel-title {
            position: relative;
            background-color: $kleur-oranje;
        }
        .panel-content {
            .panel-box {
                border: 2px solid $kleur-oranje;
            }
        }
    }
}

.embryos {
    .panel {
        background-color: rgba($kleur-blauw, .15);
        .panel-title {
            position: relative;
            background-color: $kleur-blauw;
        }
        .panel-content {
            .panel-box {
                border: 2px solid $kleur-blauw;
            }
        }
    }
}

.weefsel {
    .panel {
        background-color: rgba($kleur-groen, .15);
        .panel-title {
            position: relative;
            background-color: $kleur-groen;
        }
        .panel-content {
            .panel-box {
                border: 2px solid $kleur-groen;
            }
        }
    }
}

.eierstok {
    .panel {
        background-color: rgba($kleur-paars, .15);
        .panel-title {
            position: relative;
            background-color: $kleur-paars;
        }
        .panel-content {
            .panel-box {
                border: 2px solid $kleur-paars;
            }
        }
    }
}




// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .panel {
				.panel-content {
					.panel-box {
						img {
							min-width: 300px;
							float: right;
						}
					}
				}
    }
}



