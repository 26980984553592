@font-face {
    font-family: 'SourceSans-Regular';
    src: local("SourceSans-Regular"),
        url(./fonts/SourceSansPro-Regular.woff) format("woff"),
        url(./fonts/SourceSansPro-Regular.ttf) format("truetype"),
        url(./fonts/SourceSansPro-Regular.svg) format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSans-Semibold';
    src: local("SourceSans-Semibold"),
        url(./fonts/SourceSansPro-Semibold.woff) format("woff"),
        url(./fonts/SourceSansPro-Semibold.ttf) format("truetype"),
        url(./fonts/SourceSansPro-Semibold.svg) format("svg");
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'SourceSans-Bold';
    src: local("SourceSans-Bold"),
        url(./fonts/SourceSansPro-Bold.woff) format("woff"),
        url(./fonts/SourceSansPro-Bold.ttf) format("truetype"),
        url(./fonts/SourceSansPro-Bold.svg) format("svg");
    font-weight: normal;
    font-style: normal;
}
