@import '../../styles/colors.scss';
.cardpage {

    animation: pagefadein .3s ease forwards;

    background-color: $kleur-blauw;
	margin-top: 60px;
	.page-title {
        font-family: 'SourceSans-Semibold', sans-serif;
		color: #fff;
		margin: 0;
		padding: 60px 30px 30px 30px;
		font-size: 18px;
		letter-spacing: 1px;
	}
	.page-cards {
		margin: 0;
		padding: 0 30px;
	}
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .cardpage {
        .page-title {
            font-size: 21px;
            padding: 30px 60px;
        }
        .page-cards {
            padding: 0 60px;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .cardpage {
        .page-title {
            font-size: 30px;
            padding: 30px 60px;
        }
    }
}
