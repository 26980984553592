body {
    margin: 0;
    padding: 0;
    min-width: 300px;
    box-sizing: border-box;
    font-family: 'SourceSans-Regular', sans-serif;
    background-color: #eee;
    -webkit-overflow-scrolling: touch;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    backface-visibility: hidden;


    @keyframes pagefadein {
        0% { opacity: 0.5; }
        100% { opacity: 1; }
    }

}
