@import '../../styles/colors.scss';
.header {
    position: fixed;
    max-width: 1024px;
    min-height: 60px;
    z-index: 2;
    top: 0;
    width: 100%;
    background-color: $kleur-bijnazwart;
    box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.3);
    .header-title {
        font-family: 'SourceSans-Semibold', sans-serif;
        font-size: 18px;
        text-align: left;
        padding: 0;
        margin: 18px 60px 12px 60px;
        color: #fff;
        letter-spacing: 1px;
        cursor: pointer;
    }
    .noback {
        margin: 18px 60px 12px 30px;
    }
    button {
        border: none;
        background: none;
        -webkit-appearance: none;
        position: absolute;
        top: 6px;
        height: 49px;
        transition: transform 0.3s ease;
    }
    button:hover {
        transform: scale(1.2)
    }
    .backbutton {
        left: 0;
    }
    .backbutton.hide {
        display: none;
    }
    .hamburgerbutton {
        right: 0;
    }
    .icon {
        width: 45px;
        height: 45px;
        stroke: #fff;
        stroke-width: 4.5;
        cursor: pointer;
    }
    .hamburger.open {
        .lijn1,
        .lijn4 {
            opacity: 0;
        }
        .lijn2 {
            transform: rotate(45deg);
        }
        .lijn3 {
            transform: rotate(-45deg);
        }
    }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
    .header {
        .header-title  {
            font-size: 21px;
            margin: 15px 60px 12px 60px;
        }
    }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
    .header {
        .header-title  {
            font-size: 27px;
            margin: 12px 60px 12px 60px;
        }
    }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
    .header {
        .header-title {
            font-size: 30px;
            margin: 9px 60px 0px 60px;
        }
    }
}
